import { PaletteColorOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: string;
    highlight: string;
    mediumBlue: string;
    lightBlue: string;
    placeholderPurple: string;
    sidebarActive: string;
    lightBorder: string;
    profileHeadingDivider: string;
    infoLabel: string;
    dark: string;
    darkCardText: string;
    lightBlueBackground: string;
    darkBlueIcon: string;
    disabledIcon: string;
    chipBg: {
      active: string;
      activeText: string;
      inactive: string;
    };
    myBackground: {
      main: string;
      appBar: string;
      actionBtns: string;
      secondary: string;
    };
    lightBackground: string;
    cardBorder: {
      gray: string;
    };
    inactiveLanguageTab: string;
    scheduleCardBorder: string;
    scheduleCardActiveBg: string;
    scheduleCardDetailsBorder: string;
    scheduleCardHoverBg: string;
    scheduleCapacityCardBg: string;
    disabledScheduleCardText: string;
    onSurface: string;
    webSecondary: string;
    webSecondaryContainer: string;
    additionalBackground: string;
    outlineVariantHigh: string;
    surface: string;
    secondaryButton: string;
    outlineVariant: string;
    onBackgroundVariant: string;
    surfaceVariant: string;
    tertiaryRating: string;
    discountedPrice: string;
    secondaryContainer: string;
    additionalSuccess: string;
    additionalError: string;
    neutral: string;
    scheduleSectionBg: string;
    passengerTransferCard: string;
    ridePlanningDateGreen: string;
    ridePlanningDateGreenText: string;
    warningIcon: string;
    ridePlanningCardDetails: string;
    dragAndDropBg: string;
    dragAndDropBorder: string;
    dragAndDropText: string;
  }

  interface PaletteOptions {
    tertiary: string;
    highlight: string;
    mediumBlue: string;
    lightBlue: string;
    placeholderPurple: string;
    sidebarActive: string;
    lightBorder: string;
    profileHeadingDivider: string;
    infoLabel: string;
    dark: string;
    darkCardText: string;
    lightBlueBackground: string;
    darkBlueIcon: string;
    disabledIcon: string;
    chipBg: {
      active: string;
      activeText: string;
      inactive: string;
    };
    myBackground: {
      main: string;
      appBar: string;
      actionBtns: string;
      secondary: string;
    };
    lightBackground: string;
    cardBorder: {
      gray: string;
    };
    inactiveLanguageTab: string;
    scheduleCardBorder: string;
    scheduleCardActiveBg: string;
    scheduleCardDetailsBorder: string;
    scheduleCardHoverBg: string;
    scheduleCapacityCardBg: string;
    disabledScheduleCardText: string;
    onSurface: string;
    webSecondary: string;
    webSecondaryContainer: string;
    additionalBackground: string;
    outlineVariantHigh: string;
    surface: string;
    secondaryButton: string;
    outlineVariant: string;
    onBackgroundVariant: string;
    surfaceVariant: string;
    tertiaryRating: string;
    discountedPrice: string;
    secondaryContainer: string;
    additionalSuccess: string;
    additionalError: string;
    neutral: string;
    scheduleSectionBg: string;
    passengerTransferCard: string;
    ridePlanningDateGreen: string;
    ridePlanningDateGreenText: string;
    warningIcon: string;
    ridePlanningCardDetails: string;
    dragAndDropBg: string;
    ridePlanningDivider: string;
  }
}

const colors = {
  primary: '#182362',
  secondary: {
    main: '#2E3C88',
    light: '#D1E4FF',
    outline: '#76ADFF',
  },
  tertiary: '#FEDA1C',
  highlight: '#CA2E2E',
  mediumBlue: '#2E99C4',
  lightBlue: '#CBF0FF',
  placeholderPurple: '#6750a4',
  sidebarActive: '#2E3C88',
  lightBorder: '#0000001f',
  profileHeadingDivider: '#C0E7F6',
  infoLabel: 'rgba(0, 0, 0, 0.6)',
  disabledIcon: 'rgba(0, 0, 0, 0.28)',
  dark: '#000',
  darkCardText: '#1C1B1F',
  lightBlueBackground: '#E6F8FF',
  darkBlueIcon: '#182361',
  chipBg: {
    active: '#71EE7E',
    activeText: '#49454F',
    inactive: '#828282;',
  },
  background: {
    main: '#F0FBFF',
    appBar: '#FFFFFF',
    actionBtns: '#FFFFFF',
    secondary: '#1B76FF',
    transparentWhite: 'rgba(255, 255, 255, 0.8)',
  },
  lightBackground: '#FFFFFF',
  cardBorder: {
    gray: '#182362',
  },
  inactiveLanguageTab: '#686868',
  scheduleCardBorder: '#F2F5FE',
  scheduleCardActiveBg: '#37427E',
  scheduleCardDetailsBorder: '#DEE2F1',
  scheduleSectionBg: '#F5F6FF',
  scheduleCardHoverBg: 'rgba(255, 255, 255, 0.90)',
  scheduleCapacityCardBg: '#FBFCFE',
  disabledScheduleCardText: '#949494',
  onSurface: '#1E1E1E',
  webSecondary: '#4991FF',
  webSecondaryContainer: '#E8F1FF',
  additionalBackground: '#FBFCFE',
  outlineVariantHigh: '#CED1DF',
  surface: '#FFFFFF',
  secondaryButton: '#1B76FF',
  outlineVariant: '#DEE2F1',
  onBackgroundVariant: '#686868',
  surfaceVariant: '#F5F5F5',
  tertiaryRating: '#FEE149',
  discountedPrice: '#A74D63',
  secondaryContainer: '#A4C8FF',
  additionalSuccess: '#219653',
  additionalError: '#B3261E',
  neutral: '#E7E7E7',
  passengerTransferCard: '#e7f1ff',
  grayIcon: 'rgba(0, 0, 0, 0.54)',
  darkGreen: '#388e3c',
  ridePlanningDateGreen: '#F0F9F8',
  ridePlanningDateGreenText: '#12A58C',
  warningIcon: '#AAA400',
  warningIconBg: '#F9FAFB',
  ridePlanningCardDetails: '#4B5563',
  dragAndDropBg: '#E5E7EB',
  dragAndDropBorder: '#B1B1B1',
  ridePlanningDivider: '#D5D5D5',
};

export default colors;
